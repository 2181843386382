///
// Footer
///
.c-footer {
    p {
        font-size: inherit !important;
    }
}

///
// Soliloquy
///
.soliloquy-image {
    width: 100% !important;
}

///
// Gravity Forms
///
.gfield_label {
    font-family: $arvo-bold !important;
    font-weight: 500 !important;
}

.gform_button {
    display: inline-block !important;
    padding: rem(5px) rem(halve($base-gutter)) !important;
    font-size: rem(20px) !important;
    font-family: $arvo-bold !important;
    color: white !important;
    text-transform: uppercase !important;
    background-color: color('blue', 'darkest') !important;
    box-shadow: 3px 3px 0 color('grey', 'medium') !important;

    @include link-states() {
        background-color: color('red') !important;
    }
}

.gfield_checkbox li input[type="checkbox"] {
    margin-top: 0 !important;
}

///
// Tribe
///
.tribe-events-calendar td.tribe-events-present div[id*=tribe-events-daynum-]{
    background-color: color('blue') !important;
}

.tribe-events-tooltip {
    padding: rem(halve($base-gutter)) !important;
}

.tribe-events-single-event-title {
    font-size: rem(30px) !important;

    // Medium Desktops
    @include media(md) {
        font-size: rem(40px) !important;
    }
}

.tribe-events-schedule {
    h2 {
        font-weight: 500 !important;
    }
}

.tribe-events-single-section-title {
    font-size: rem(22px) !important;

    // Medium Desktops
    @include media(md) {
        font-size: rem(24px) !important;
    }
}

.tribe-events-event-meta {
    background-color: color('grey') !important;
}

.tribe-events-meta-group-details {
    dt,
    dd {
        font-weight: 500 !important;
    }
}

.tribe-events-button {
    display: inline-block !important;
    width: auto !important;
    height: auto !important;
    padding: rem(5px) rem(halve($base-gutter)) !important;
    font-size: rem(20px) !important;
    font-family: $arvo-bold !important;
    font-weight: 500 !important;
    color: white !important;
    line-height: 1.5 !important;
    text-transform: uppercase !important;
    background-color: color('blue', 'darkest') !important;
    box-shadow: 3px 3px 0 color('grey', 'medium') !important;
    border-radius: 0 !important;

    @include link-states() {
        background-color: color('red') !important;
    }
}

.tribe-events-back a,
.tribe-events-sub-nav a {
    font-weight: 500 !important;
}

.tribe-events-month-event-title {
    font-size: rem(14px) !important;
    text-shadow: none !important;
}

.tribe-events-schedule {
    h2 {
        color: $base-font-color;
        font-size: rem(24px) !important;
        font-family: $montserrat-light !important;
        text-shadow: none;
        text-transform: none;
    }
}

#tribe-bar-form input[type=text] {
    font-style: normal !important;
}

#tribe-events-footer {
    border-color: $base-font-color !important;
}
