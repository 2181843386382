h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-weight: 500;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: $arvo-bold;
}

h3,
h4,
h6 {
    color: color('red');
}

h2,
h5 {
    color: color('blue');

    .c-section--blue &,
    .c-section--red & {
        color: inherit;
        text-shadow: 3px 3px 0 color('blue', 'darkest');
    }
}

h2,
h3 {
    text-shadow: 3px 3px 0 color('grey', 'medium');
}

h1 {
    @include font-size('h1');
    color: white;
    text-shadow: 3px 6px 0 color('blue', 'darkest'), -1px -1px 0 color('blue', 'darkest'), 1px -1px 0 color('blue', 'darkest'), -1px 1px 0 color('blue', 'darkest'), 1px 1px 0 color('blue', 'darkest');
}

h2 {
    @include font-size('h2');
}

h3 {
    @include font-size('h3');
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(20px);

    &:not(:last-child) {
        margin-bottom: rem($base-gutter);
    }

    .u-text-small & {
        font-size: inherit;
    }
}

li {
    &:not([class]) {
        font-size: rem(20px);
    }

    .u-text-small & {
        font-size: inherit;
    }
}

dt,
dd,
strong {
    font-weight: 500;
}

dt,
dd {
    font-size: rem(20px);

    .u-text-small & {
        font-size: inherit;
    }
}

dt,
strong {
    font-family: $arvo-bold;
}

td {
    font-size: rem(20px);

    .u-text-small & {
        font-size: inherit;
    }
}

a {
    color: $base-font-color;
    text-decoration: none;

    @include link-states() {
        color: color('blue');
    }
}
