///
// Section
///
.c-section {
    padding-top: rem($base-gutter);
    padding-bottom: rem($base-gutter);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(double($base-gutter));
        padding-bottom: rem(double($base-gutter));
    }
}

///
// Red
///
.c-section--red {
    color: white;
    background-color: color('red');
    background-image: url('../../dist/images/bg-red.jpg');
}

///
// Blue
///
.c-section--blue {
    color: white;
    background-color: color('blue');
    background-image: url('../../dist/images/bg-blue.jpg');
}

///
// Grey
///
.c-section--grey {
    background-color: color('grey');
}

///
// Intro
///
.c-section--intro {
    p {
        // Medium Desktops
        @include media(md) {
            font-size: rem(30px);
        }
    }
}

///
// Work & Events
///
.c-section--work,
.c-section--events {
    // Medium Desktops
    @include media(md) {
        padding-bottom: rem(120px);
    }
}

///
// Events
///
.c-section--events {
    padding-bottom: rem(120px);
}

///
// About
///
.c-section--about {
    .l-col:last-child {
        // Medium Desktops
        @include media(md) {
            margin-top: neg(rem(120px));
        }
    }
}

///
// President
///
.c-section--president {
    .l-col:first-child {
        //margin-top: neg(rem(120px));
    }
}

///
// Newsletter
///
.c-section--newsletter {
    h4 {
        font-size: rem(28px); // NOTE: not consistent with style guide
        color: white;
        text-shadow: 3px 3px 0 color('blue', 'darkest');
    }
}

///
// Main
///
.c-section--main {
    padding-top: 0;
}

///
// Header
///
.c-section__header {
    padding-top: rem(halve($base-gutter));
    padding-bottom: rem(halve($base-gutter));
    background-color: color('red');

    h1 {
        margin-bottom: 0;
    }
}

///
// Content
///
.c-section__content {
    padding-top: rem($base-gutter);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(double($base-gutter));
    }
}
