///
// Group
///
.c-staff-group {
    + .c-staff-group {
        margin-top: rem($base-gutter);
        padding-top: rem($base-gutter);
        border-top: 1px solid $base-font-color;
    }
}

///
// Members
///
.c-staff-members {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
}

///
// Member
///
.c-staff-member {
    width: 100%;
    margin-bottom: rem($base-gutter);
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Extra Small
    @include media(xs) {
        width: 50%;
    }

    // Medium Desktops 
    @include media(md) {
        width: 25%;
    }
}

///
// Image
///
.c-staff-member__image {
    margin-bottom: rem(halve($base-gutter));

    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

///
// Name
///
.c-staff-member__name {
    margin-bottom: 0;
}