///
// Mobile
///
.c-nav--mobile {
    display: none;

    // Medium Max Desktops
    @include media-max(md) {
        position: relative;
        z-index: 70;
        margin-left: neg(rem(halve($base-gutter)));
        margin-right: neg(rem(halve($base-gutter)));
        padding-top: rem(50px);
        padding-bottom: rem(halve($base-gutter));
        background-color: color('red');
        clear: both;
    }
}

///
// Left & Right
///
.c-nav--left,
.c-nav--right {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }
}

///
// Left
///
.c-nav--left {
    // Medium Desktops
    @include media(md) {
        float: left;
    }
}

///
// Right
///
.c-nav--right {
    // Medium Desktops
    @include media(md) {
        float: right;
    }
}

///
// Posts
///
.c-nav--posts {
    margin-top: rem($base-gutter);
}

///
// List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

///
// Item
///
.c-nav__item {
    position: relative;

    .c-nav--left &,
    .c-nav--right &,
    .c-nav--mobile & {
        a {
            color: white;
            text-transform: uppercase;
        }
    }

    .c-nav--left &,
    .c-nav--right & {
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            margin-top: rem(halve($base-gutter));
            margin-bottom: rem(halve($base-gutter));
            vertical-align: top;
        }

        &:hover {
            .c-nav__dropdown {
                // Medium Desktops
                @include media(md) {
                    display: block;
                }
            }
        }

        a {
            // Medium Desktops 
            @include media(md) {
                font-size: rem(14px);
            }

            // Large Desktops 
            @include media(lg) {
                font-size: rem(16px);
            }
        }
    }

    .c-nav--left & {
        // Medium Desktops
        @include media(md) {
            margin-right: rem(10px);
        }

        // Large Desktops
        @include media(lg) {
            margin-right: rem(20px);
        }
    }

    .c-nav--right & {
        // Medium Desktops
        @include media(md) {
            margin-left: rem(10px);
        }

        // Large Desktops
        @include media(lg) {
            margin-left: rem(20px);
        }
    }

    .c-nav--mobile & {
        margin-left: rem(halve($base-gutter));
        margin-right: rem(halve($base-gutter));

        + .c-nav__item {
            // Medium Max Desktops
            @include media-max(md) {
                margin-top: rem(10px);
            }
        }
    }

    .c-nav--posts & {
        display: inline-block;
        vertical-align: top;

        + .c-nav__item {
            margin-left: rem(halve($base-gutter));
        }

        a {
            padding: rem(5px) rem(10px);
            color: white;
            background-color: color('blue', 'darkest');

            @include link-states() {
                background-color: color('red');
            }
        }

        .current {
            padding: rem(5px) rem(10px);
            color: white;
            background-color: color('red');
        }
    }
}

///
// Dropdown
///
.c-nav__dropdown {
    display: none;
    margin-bottom: 0;
    padding-left: 0;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(halve($base-gutter));
        margin-bottom: rem(halve($base-gutter));
        padding-left: rem(halve($base-gutter));
    }

    // Medium Desktops
    @include media(md) {
        position: absolute;
        top: 100%;
        left: neg(15px);
        z-index: z('dropdown');
        min-width: 250px;
        padding: rem(halve($base-gutter));
        background-color: color('red');
    }

    .c-nav__item {
        display: block;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;

        + .c-nav__item {
            margin-top: rem(10px);

            // Medium Desktops
            @include media(md) {
                margin-top: rem(halve($base-gutter));
            }
        }
    }

    .is-expanded & {
        // Medium Max Desktops
        @include media-max(md) {
            display: block;
        }
    }
}
