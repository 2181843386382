///
// Small
///
.u-text-small {
    font-size: rem(12px);
}

///
// Generic text transforms
///
.u-text-lowercase {
    text-transform: lowercase;
}

.u-text-uppercase {
    text-transform: uppercase;
}

.u-text-none {
    text-transform: none;
}

///
// Generic text aligns
///
.u-text-left {
    text-align: left;
}

.u-text-right {
    text-align: right;
}

.u-text-center {
    text-align: center;
}

///
// Create breakpoint based text aligns
///
@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        .u-text-left\@#{$breakpoint} {
            text-align: left;
        }
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        .u-text-right\@#{$breakpoint} {
            text-align: right;
        }
    }
}

@each $breakpoint in map-keys($breakpoints) {
    @include media($breakpoint) {
        .u-text-center\@#{$breakpoint} {
            text-align: center;
        }
    }
}
