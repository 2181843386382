///
// Logo
///
.c-logo {
    display: block;
    position: absolute;
    top: neg(65px);
    left: 0;
    right: 0;
    z-index: z('logo');
    width: 113px;
    height: 145px;
    margin-left: auto;
    margin-right: auto;
}
