///
// Posts
///
.c-posts--home {
    display: flex;
    flex-wrap: nowrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
}

///
// Post
///
.c-post {
    .c-posts--home & {
        position: relative;
        width: 100%;
        padding-left: rem(halve($base-gutter));
        padding-right: rem(halve($base-gutter));

        // Medium Desktops
        @include media(md) {
            width: 33.3333333333%;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 15px;
            right: 15px;
            width: auto;
            height: 100%;
            background-color: white;
        }
    }

    .blog & {
        + .c-post {
            margin-top: rem($base-gutter);
        }
    }
}

///
// Inner
///
.c-post__inner {
    .c-posts--home & {
        position: relative;
        z-index: z('inner');
        padding: rem(halve($base-gutter));
    }
}

///
// Media
///
.c-post__media {
    .c-posts--home & {
        margin: neg(halve($base-gutter)) neg(halve($base-gutter)) halve($base-gutter);
        border-bottom: 3px solid color('grey');

        img {
            width: 100%;
        }
    }
}

///
// More
///
.c-post__more {
    font-size: rem(12px);
    font-family: $montserrat-regular;
    color: color('blue');
    text-transform: uppercase;
}

///
// Header
///
.c-post__header {
    margin-bottom: rem(20px);
}

///
// Title
///
.c-post__title {
    margin-bottom: 0;

    a {
        color: color('red');
    }
}
