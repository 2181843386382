///
// Links
///
.c-social-links {
    margin-top: rem(halve($base-gutter));
    margin-bottom: rem(halve($base-gutter));
}

///
// Link
///
.c-social-link {
    + .c-social-link {
        margin-left: rem(10px);
    }

    .fa {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: rem(16px);
        color: white;
        border-radius: 50%;
    }

    .fa-facebook {
        background-color: social('facebook');
    }

    .fa-twitter {
        background-color: social('twitter');
    }

    .fa-linkedin {
        background-color: social('linkedin');
    }

    .fa-google-plus {
        background-color: social('googleplus');
    }

}
