///
// Footer
///
.c-footer {
    padding-top: rem($base-gutter);
    padding-bottom: rem($base-gutter);
    font-size: rem(13px);

    // Medium Max Desktops
    @include media-max(md) {
        text-align: center;
    }

    // Medium Desktops
    @include media(md) {
        padding-top: rem(double($base-gutter));
        padding-bottom: rem(double($base-gutter));
    }

    a {
        color: $base-font-color;
    }
}

///
// Copyright
///
.c-footer__copyright {
    font-size: rem(12px);
}
