input,
select,
textarea {
    width: 100%;
    height: 39px;
    padding: rem(10px);
    color: $base-font-color;
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
}

input[type="submit"] {
    display: inline-block;
    width: auto;
    padding: rem(5px) rem(halve($base-gutter));
    font-size: rem(20px);
    font-family: $arvo-bold;
    color: white;
    text-transform: uppercase;
    background-color: color('blue', 'darkest');
    border: 0;
    box-shadow: 3px 3px 0 color('grey', 'medium');

    @include link-states() {
        color: white;
        background-color: color('blue');
    }
}

input[type="checkbox"] {
    height: auto;
}

::-webkit-input-placeholder {
    color: $base-font-color;
}

::-moz-placeholder {
    color: $base-font-color;
}

:-ms-input-placeholder {
    color: $base-font-color;
}

:-moz-placeholder {
    color: $base-font-color;
}
