///
// Pre
///
.c-header__pre {
    display: none;

    // Medium Desktops
    @include media(md) {
        display: block;
    }
}
///
// Top
///
.c-header__top {
    background-color: color('red');
    border-top: 70px solid white;
    border-bottom: 1px solid color('blue');

    // Medium Max Desktops
    @include media-max(sm) {
        min-height: 145px;
    }
}

///
// Bottom
///
.c-header__bottom {
    padding-top: rem(halve($base-gutter));
    padding-bottom: rem(halve($base-gutter));
    color: white;
    background-color: color('blue');
    background-image: url('../../dist/images/bg-blue.jpg');

    h1,
    h3 {
        margin-bottom: 0;
    }

    h3 {
        color: inherit;
        letter-spacing: 5px;
        text-shadow: 3px 3px 0 color('blue', 'darkest');

        // Medium Max Desktops
        @include media-max(md) {
            font-size: rem(14px);
        }
    }
}
