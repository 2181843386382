///
// Button
///
.c-btn {
    display: inline-block;
    padding: rem(5px) rem(halve($base-gutter));
    font-size: rem(20px);
    font-family: $arvo-bold;
    color: white;
    text-transform: uppercase;
    box-shadow: 3px 3px 0 color('grey', 'medium');

    @include link-states() {
        color: white;
    }
}

///
// Blue
///
.c-btn--blue {
    background-color: color('blue', 'darkest');

    @include link-states() {
        background-color: color('red');
    }

    .c-section--red & {
        @include link-states() {
            background-color: color('blue');
        }
    }
}

///
// Red
///
.c-btn--red {
    background-color: color('red');

    @include link-states() {
        background-color: color('blue', 'darkest');
    }
}
