///
// Events
///
.c-events {
    display: flex;
    flex-wrap: nowrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
}

///
// Event
///
.c-event {
    position: relative;
    width: 100%;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Medium Desktops
    @include media(md) {
        width: 25%;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        width: auto;
        height: 100%;
        background-color: white;
    }
}

///
// Inner
///
.c-event__inner {
    position: relative;
    z-index: z('inner');
    padding: rem(halve($base-gutter));
}

///
// Media
///
.c-event__media {
    margin: neg(halve($base-gutter)) neg(halve($base-gutter)) halve($base-gutter);
    border-bottom: 3px solid color('grey');
}

///
// Date
///
.c-event__date {
    display: block;
    margin-bottom: rem(10px);
}

///
// Title
///
.c-event__title {
    margin-bottom: 0;

    a {
        color: color('red');

        @include link-states() {
            color: color('blue');
        }
    }
}
