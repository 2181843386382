///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }

@font-face {
    font-family: 'arvobold';
    src: url('../../dist/fonts/arvo-bold-webfont.woff2') format('woff2'),
         url('../../dist/fonts/arvo-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arvobold_italic';
    src: url('../../dist/fonts/arvo-bolditalic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/arvo-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arvoitalic';
    src: url('../../dist/fonts/arvo-italic-webfont.woff2') format('woff2'),
         url('../../dist/fonts/arvo-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arvoregular';
    src: url('../../dist/fonts/arvo-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/arvo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('../../dist/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../../dist/fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratlight';
    src: url('../../dist/fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../../dist/fonts/montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
