///
// Toggle
///
.c-toggle {
    float: right;
    position: relative;
    z-index: z('toggle');
    width: 43px;
    height: 43px;
    margin-top: rem(halve($base-gutter));
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    outline: none;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    &:before {
        content: 'Menu';
        position: absolute;
        top: 5px;
        left: neg(65px);
        font-size: rem(21px);
        color: white;
        text-transform: uppercase;

        // SE
        @media (min-width: 319px) and (max-width: 320px) {
            top: 9px;
            left: neg(45px);
            font-size: rem(16px);
        }
    }
}

///
// Icon
///
.c-toggle__icon {
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    width: 25px;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    transition: transform 0.3s;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
        margin-left: auto;
        margin-right: auto;
        background-color: inherit;
        transition: transform 0.3s ease-in-out;
    }

    &:after {
        top: 8px;
    }

    &:before {
        bottom: 8px;
    }

    .is-open & {
        background-color: transparent;

        &:after,
        &:before {
            top: 0;
            background-color: white;
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:before {
            transform: rotate(45deg);
        }
    }
}

///
// Dropdown
///
.c-toggle-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    &:after {
        content: '\f107';
        font-size: rem(18px);
        font-family: 'FontAwesome';
        color: white;

        .is-expanded & {
            content: '\f106';
        }
    }
}
