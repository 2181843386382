///
// Blocks
///
.c-blocks {
    display: flex;
    flex-wrap: nowrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));
}

///
// Block
///
.c-block {
    width: 100%;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Medium Desktops
    @include media(md) {
        width: 16.6666666667%;
    }

    &:not(.c-block--info) {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    li + li {
        margin-top: rem(5px);
    }
}

///
// Title
///
.c-block__title {
    font-size: rem(16px);
}
