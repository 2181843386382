///
// Publications
///
.c-publications {
    display: flex;
    flex-wrap: wrap;
    margin-left: neg(rem(halve($base-gutter)));
    margin-right: neg(rem(halve($base-gutter)));

    // Medium Desktops
    @include media(md) {
        flex-wrap: nowrap;
    }
}

///
// Publication
///
.c-publication {
    width: 100%;
    padding-left: rem(halve($base-gutter));
    padding-right: rem(halve($base-gutter));

    // Medium Desktops
    @include media(md) {
        width: 25%;
    }
}

///
// Title
///
.c-publication__title {
    margin-top: rem(20px);
}
