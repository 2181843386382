body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $montserrat-light;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

hr {
    height: 1px;
    margin-top: rem($base-gutter);
    margin-bottom: rem($base-gutter);
    background-color: $base-font-color;
    border: 0;

    // Medium Desktops
    @include media(md) {
        margin-top: rem(double($base-gutter));
        margin-bottom: rem(double($base-gutter));
    }
}

ol:not([class]),
ul:not([class]) {
    li:not([class]) {
        + li:not([class]) {
            margin-top: rem(halve($base-gutter));
        }
    }
}

ul:not([class]) {
    margin-top: 0;
    margin-bottom: rem($base-gutter);
    padding-left: rem(halve($base-gutter));
    list-style: none;

    li:not([class]) {
        position: relative;
        padding-left: rem($base-gutter);

        &:before {
            content: '\2605';
            position: absolute;
            top: 0;
            left: 0;
            color: color('blue');
        }
    }
}
