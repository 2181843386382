///
// Breakpoints
///
$breakpoints: (
    xs: 480px,  // Extra Small devices (phones landscape, 480px and up)
    sm: 768px,  // Small devices (tablets portrai, 768px and up)
    md: 1024px, // Medium devices (tablets landscape, desktops, 1024px and up)
    lg: 1200px, // Large devices (desktops, 1200px and up)
    xl: 1500px  // Extra Lage devices (desktops, 1500px and up)
);

///
// Base global defaults
///
$base-columns:      12;
$base-gutter:       30px;
$base-font-size:    16px;
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-line-height:  1.5;
$base-font-color:   #112c3b;

///
// Font sizes
///
$font-sizes: (
    'h1': (
        'initial':  29px,
        'sm':       68px
    ),
    'h2': (
        'initial':  38px,
        'sm':       48px
    ),
    'h3': (
        'initial':  30px,
        'sm':       40px
    ),
    'h4': (
        'initial':  22px,
        'sm':       24px
    ),
    'h5':           22px,
    'h6':           22px
);

///
// Enable flexbox grid
//
// Note that this is experimental so use at your own risk.
///
$flexbox: false;

///
// Column offsets
//
// Will enable the creation of column offset classes
///
$column-offsets: false;

///
// Debug mode
//
// In order to use the debug mode you need to set the below variable to true
// and you also need to set the THEME_DEBUG constant in the wp-config to true
///
$debug: false;

///
// Colors Map for @function color();
///
$colors: (
    'grey': (
        'default':      #efefef,
        'medium':       #cccccc,
        'dark':         #c0c0c0
    ),
    'bigrigmedia': (
        'orange':       #f6921e,
        'grey':         #4d4d4d
    ),
    'blue': (
        'default':      #12547a,
        'darkest':      #112c3b
    ),
    'red': (
        'default':      #b4262c
    )
);

///
// Alerts Map for @function alert();
///
$alerts: (
    'success': (
        'default':  #dff0d8,
        'message':  #3c763d,
        'border':   #d6e9c6
    ),
    'info': (
        'default':  #d9edf7,
        'message':  #31708f,
        'border':   #bce8f1
    ),
    'warning': (
        'default':  #fcf8e3,
        'message':  #8a6d3b,
        'border':   #faf2cc
    ),
    'error': (
        'default':  #f2dede,
        'message':  #a94442,
        'border':   #ebcccc
    )
);

///
// Social Networks Map for @function social();
///
$social-networks: (
    facebook:   #3b5998,
    twitter:    #00aced,
    googleplus: #dd4b39,
    linkedin:   #007bb6,
    youtube:    #bb0000,
    instagram:  #517fa4,
    pinterest:  #cb2027,
    flickr:     #ff0084,
    tumblr:     #32506d,
    foursquare: #0072b1,
    dribble:    #ea4c89,
    vine:       #00bf8f,
    yelp:       #c41200
);

///
// Z-index Map for @function z();
///
$z-index: (
    debug: 1,
    behind: -1,
    inner: 10,
    dropdown: 50,
    toggle: 80,
    logo: 90
);

///
// Fonts
///
$montserrat-regular: 'montserratregular';
$montserrat-light: 'montserratlight';
$arvo-bold: 'arvobold';
