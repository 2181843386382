///
// Form
///
.c-form {
    width: 100%;
}

.c-form--newsletter {
    max-width: 591px;
    margin-left: auto;
    margin-right: auto;

    // Medium Desktops 
    @include media(md) {
        padding-left: rem(82px);
        background-image: url('../../dist/images/icon-mail.png');
        background-position: left center;
        background-repeat: no-repeat;
    }
}

///
// Button
///
.c-form__btn {
    .c-form--newsletter & {
        // Medium Max Breakpoint 
        @include media-max(md) {
            display: block;
            margin-top: rem(halve($base-gutter));
            margin-left: auto;
            margin-right: auto;
        }
    }
}